<template>
    <div class="page">
        <div class="convertDetail">
            <div class="header">
                <img src="../assets/icon_line.png" alt=""/>
                请选择您的{{convertDetailName}}
                <img src="../assets/icon_line.png" alt=""/>
            </div>
            <div class="convertDetailList">
                <ConvertDetailItem v-for="(item,key) in convertDetailList" :key="'convertDetailListItem_'+key" :item="item"
                                   v-on:clickConvertDetail="clickConvertDetail"/>
            </div>
            <div class="bottom">
                <BottomCom/>
            </div>
        </div>
        <div class="footer">
            <span @click="goHome">我的权益</span>
            <span @click="goOrder">兑换中心</span>
        </div>
        <ProductModal :modal="productModal" :proList="proList" v-on:closeModal="closeProductModal"/>
    </div>
</template>

<script>
    import BottomCom from "../components/BottomCom";
    import ConvertDetailItem from "../components/ConvertDetailItem";
    import {getConvertDetailList, getProductGroup} from "../api/data";
    import _ from "lodash";
    import {Toast} from "vant";
    import ProductModal from "../components/ProductModal";

    export default {
        name: "ConvertDetail",
        components: {ProductModal, ConvertDetailItem, BottomCom},
        data: function () {
            return {
                productModal: false,
                convertId: null,
                convertDetailName: null,
                convertDetailList: [],
                proList: []
            }
        },
        async beforeMount() {
            await this.queryConvertDetailList();
        },
        methods: {
            async queryConvertDetailList() {
                let param = {
                    id: _.toInteger(this.$route.query.id)
                };
                let res = await getConvertDetailList(param);
                if (_.toInteger(res.code) === 1) {
                    // let array = [];
                    // array = array.concat(res.data.data);
                    // array = array.concat(res.data.data);
                    this.convertDetailList = res.data.data;
                    this.convertDetailName = res.data.name;
                } else {
                    Toast.fail(res.msg);
                }
            },
            async closeProductModal(goodId) {
                let id = this.convertId;
                if (goodId) {
                    this.productModal = false;
                    this.convertId = null;
                    await this.$router.push({name: 'ProductDetail', query: {id: _.toString(id), goodId: _.toString(goodId)}});
                } else {
                    this.convertId = null;
                    this.productModal = false;
                }
            },
            async goHome() {
                await this.$router.push('/')
            },
            async goOrder() {
                await this.$router.push('/Order')
            },
            async clickConvertDetail(id, goodId, type) {
                if (_.toInteger(type) === 2) {
                    let param = {
                        id: _.toInteger(id),
                        goods_id: goodId
                    };
                    let res = await getProductGroup(param);
                    if (_.toInteger(res.code) === 1) {
                        this.proList = res.data.data;
                        this.convertId = id;
                        this.productModal = true;
                    } else {
                        Toast.fail(res.msg);
                    }
                } else {
                    await this.$router.push({name: 'ProductDetail', query: {id: _.toString(id), goodId: _.toString(goodId)}});
                }
            }
        }
    }
</script>

<style scoped>
    .convertDetail {
        display: flex;
        flex-direction: column;
        width: 690px;
        flex: 1;
        justify-content: flex-start;
        align-items: center;
        padding: 24px 24px 144px 24px;
        box-sizing: border-box;
    }

    .convertDetail .header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 40px 0;
        font-size: 36px;
        height: 50px;
        font-weight: bold;
        color: #A05923;
    }

    .convertDetail .header img {
        width: 19px;
        height: 19px;
        margin: 0 20px;
    }

    .convertDetail .convertDetailList {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex: 1;
    }

    .convertDetail .bottom {
        width: 642px;
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .page .footer {
        position: fixed;
        bottom: 0;
        background: #FFFFFF;
        width: 690px;
        height: 120px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
    }

    .page .footer span {
        flex: 1;
        margin: 0 20px;
        height: 80px;
        border-radius: 40px;
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .page .footer span:first-child {
        border: 2px solid #222529;
        color: #212429;
    }

    .page .footer span:last-child {
        border: 2px solid #707070;
        background: linear-gradient(125deg, #3A3D43 0%, #202327 100%);
        color: #E2BE93;
    }
</style>
