<template>
    <div class="convertDetailItem" @click="goConvertDetail(item.id,item.goods_id,item.specificatio)">
        <div class="left">
            <img :src="item.goods_image" :alt="item.goods_name"/>
        </div>
        <div class="body">
            <div class="top van-ellipsis">{{item.goods_name}}</div>
            <div class="desc van-multi-ellipsis--l2">{{item.remarks}}</div>
            <div class="price">市场价:￥<span>{{item.price}}</span></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ConvertDetailItem",
        props: ['item'],
        methods: {
            getStatusName(type) {
                // type 1未选择,2已选择,3已兑换
                let typeValue = _.toInteger(type);
                switch (typeValue) {
                    case 1:
                        return '待领取';
                    case 2:
                        return '已选择';
                    case 3:
                        return '已领取';
                    default:
                        return '未知';
                }
            },
            goConvertDetail(id, goodId, type) {
                this.$emit('clickConvertDetail', id, goodId, type);
            }
        }
    }
</script>

<style scoped>
    .convertDetailItem {
        width: 642px;
        height: 228px;
        background: url("../assets/goog_bg.png") no-repeat left top;
        background-size: 100% auto;
        margin-bottom: 25px;
        position: relative;
        box-sizing: border-box;
        padding: 35px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
    }


    .convertDetailItem .left {
        width: 158px;
        height: 158px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .convertDetailItem .left img {
        max-width: 100%;
        max-height: 100%;
    }

    .convertDetailItem .body {
        margin-left: 20px;
        display: flex;
        width: 100%;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: 168px;
        overflow: hidden;
    }

    .convertDetailItem .body .top {
        width: 100%;
        height: 38px;
        font-size: 27px;
        font-weight: 500;
        color: #E8C8AF;
    }

    .convertDetailItem .body .desc {
        width: 100%;
        margin-top: 10px;
        font-size: 22px;
        font-weight: 500;
        line-height: 30px;
        overflow: hidden;
        color: #B19485;
    }

    .convertDetailItem .body .price {
        margin-top: 20px;
        width: 100%;
        font-size: 20px;
        font-weight: 400;
        color: #E8C8AF;
    }

    .convertDetailItem .body .price span {
        font-size: 29px;
        font-weight: bold;
        color: #E8C8AF;
    }
</style>
