<template>
    <van-popup class="model" :close-on-click-overlay="false" @click-overlay="closeModal" v-model="modal">
        <div class="productBody">
            <div class="title">此权益包含两项礼品</div>
            <div class="item" v-for="pro in proList" :key="'pro_'+pro.goods_id" @click="choosePro(pro.goods_id)">{{pro.goods_name}}</div>
            <div class="button" @click="closeModal">返回</div>
        </div>
    </van-popup>
</template>

<script>
    export default {
        name: "ProductModal",
        props: ['modal', 'proList'],
        methods: {
            choosePro(goodId) {
                this.$emit('closeModal', goodId)
            },
            closeModal() {
                this.$emit('closeModal')
            }
        }
    }
</script>

<style scoped>
    .model {
        background: transparent;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .model .productBody {
        width: 612px;
        background: #FFFFFF;
        border-radius: 16px;
        box-sizing: border-box;
        padding: 40px;
    }

    .model .productBody .title {
        width: 100%;
        font-size: 36px;
        font-weight: bold;
        line-height: 36px;
        color: #171A1D;
        text-align: center;
        margin: 30px 0 60px 0;
    }

    .model .productBody .item {
        margin-top: 30px;
        min-height: 80px;
        background: linear-gradient(125deg, #3A3D43 0%, #202327 100%);
        border-radius: 40px;
        box-sizing: border-box;
        padding: 10px 20px;
        font-size: 30px;
        font-weight: 500;
        color: #E2BE93;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .model .productBody .button {
        margin-top: 50px;
        margin-bottom: 50px;
        height: 80px;
        border: 2px solid #222529;
        border-radius: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
